import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View } from 'react-native';
import SearchPage from './components/Search';
import { colorScheme } from './components/styles';

export default function App() {
  return (
    <SearchPage />
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colorScheme.backgroundLight,
  },
});
