import React, { forwardRef } from 'react';
import { TouchableOpacity, TextInput, StyleSheet } from 'react-native';
import { colorScheme, primaryFont, secondaryFont, scaleFontSize } from './styles';
import { isLoading } from 'expo-font';

const SearchInput = forwardRef(({ value, onChangeText, onFocus, onSubmitEditing, onBlur, isLoading, style }, ref) => {
    return (
        <TouchableOpacity
            style={[styles.inputContainer, onFocus ? styles.focusedInputContainer : {}, style]}
            onPress={onFocus}
            hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
            activeOpacity={1}
        >
            <TextInput
                ref={ref}
                style={styles.input}
                value={value}
                onChangeText={onChangeText}
                placeholder="I wonder what the Bible says about..."
                onSubmitEditing={onSubmitEditing}
                onBlur={onBlur}
                editable={!isLoading}
                accessibilityLabel="Search Input"
            />
        </TouchableOpacity>
    );
});

const styles = StyleSheet.create({
    // ... inputContainer, focusedInputContainer, input
    inputContainer: {
        borderRadius: '15%',
        paddingHorizontal: '5%',
        paddingVertical: '1%',
        backgroundColor: colorScheme.neutralVeryLight,
        elevation: 5,
        borderColor: colorScheme.primaryLight,
    },
    input: {
        fontSize: scaleFontSize(15),
        minHeight: '5%',
        fontFamily: primaryFont,
        color: colorScheme.secondaryDark,
    },
});

export default SearchInput;
