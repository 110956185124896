import { Dimensions, PixelRatio, } from 'react-native';

const { width: SCREEN_WIDTH } = Dimensions.get('window');
const BASE_WIDTH = 375; // A reference width, such as the width of an iPhone X

export const scaleFontSize = (size) => {
    const newSize = (size * SCREEN_WIDTH) / BASE_WIDTH;
    return parseFloat(newSize.toFixed(2));
};


// Lavendar
// export const primaryFont = 'Cabin_400Regular';
// export const secondaryFont = 'Cabin_700Bold';
// export const colorScheme = {
//     primaryDark: "#5D576B",
//     primary: "#8B7C93",
//     primaryLight: "#B7ABC6",
//     primaryVeryLight: "#E1DAE7",

//     secondaryDark: "#2C5F2D",
//     secondary: "#47A047",
//     secondaryLight: "#75C975",
//     secondaryVeryLight: "#A3F3A3",

//     tertiaryDark: "#965D62",
//     tertiary: "#C78587",
//     tertiaryLight: "#E3ADAF",
//     tertiaryVeryLight: "#F4D4D6",

//     neutralDark: "#4B4B4B",
//     neutral: "#838383",
//     neutralLight: "#BCBCBC",
//     neutralVeryLight: "#E6E6E6",

//     backgroundDark: "#282C34",
//     backgroundLight: "#F7F7F7",
// }

// Ocean Breeze
// export const primaryFont = 'Roboto_400Regular';
// export const secondaryFont = 'Roboto_700Bold';
// export const colorScheme = {
//     primaryDark: "#204459",
//     primary: "#3A6F81",
//     primaryLight: "#6AA1AE",
//     primaryVeryLight: "#9FCCDB",

//     secondaryDark: "#4E4E50",
//     secondary: "#737373",
//     secondaryLight: "#A6A6A6",
//     secondaryVeryLight: "#D9D9D9",

//     tertiaryDark: "#26332E",
//     tertiary: "#3C4D45",
//     tertiaryLight: "#6C8371",
//     tertiaryVeryLight: "#9DBA9E",

//     neutralDark: "#2C2C2C",
//     neutral: "#585858",
//     neutralLight: "#858585",
//     neutralVeryLight: "#E6E6E6",

//     backgroundDark: "#1C2024",
//     backgroundLight: "#F0F0F0",
// }

// Sunset serenade
// export const primaryFont = 'Poppins_400Regular';
// export const secondaryFont = 'Poppins_700Bold';
// export const colorScheme = {
//     primaryDark: "#83394C",
//     primary: "#C45A6C",
//     primaryLight: "#E38A9C",
//     primaryVeryLight: "#F7B9CA",

//     secondaryDark: "#6C441D",
//     secondary: "#B37B2D",
//     secondaryLight: "#E0AE5A",
//     secondaryVeryLight: "#FCD88B",

//     tertiaryDark: "#3E3B3C",
//     tertiary: "#5A5456",
//     tertiaryLight: "#878181",
//     tertiaryVeryLight: "#B5AEAF",

//     neutralDark: "#4B4B4B",
//     neutral: "#5C5C5C",
//     neutralLight: "#868686",
//     neutralVeryLight: "#E6E6E6",

//     backgroundDark: "#22262A",
//     backgroundLight: "#E9E9E9",
// }

// Forest
export const primaryFont = 'Poppins_400Regular';
export const secondaryFont = 'Poppins_700Bold'
export const titleFont = 'Poppins_700Bold_Italic';
export const colorScheme = {
    primaryDark: "#2E5339",
    primary: "#495F41",
    primaryLight: "#346F4A",
    primaryVeryLight: "#F7B9CA",

    secondaryDark: "#563635",
    secondary: "#7E4F4E",
    secondaryLight: "#AA7574",
    secondaryVeryLight: "#FFD8D6",

    tertiaryDark: "#3E3B3C",
    tertiary: "#5A5456",
    tertiaryLight: "#878181",
    tertiaryVeryLight: "#B5AEAF",

    neutralDark: "#4B4B4B",
    neutral: "#5C5C5C",
    neutralLight: "#868686",
    neutralVeryLight: "#E6E6E6",

    backgroundDark: "#22262A",
    backgroundLight: "#E9E9E9",
}
