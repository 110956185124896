import React, { useState } from 'react';
import { View, Text, StyleSheet, TouchableHighlight } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { colorScheme, primaryFont, secondaryFont, scaleFontSize } from './styles';
import * as Haptics from 'expo-haptics';

const SearchResult = ({ location, verse, singleVerse, similarity, style }) => {
    let verses = [];
    const [isExpanded, setIsExpanded] = useState(false);

    const locationRowStyle = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: isExpanded ? '4.5%' : 0,
        borderBottomWidth: isExpanded ? '1%' : 0,
        borderBottomColor: colorScheme.primaryDark,
    };

    if (!singleVerse) {
        // Match the verse number and text using regular expressions
        const versePattern = /(\d+)([^\d]+|$)/g;
        let match;

        while ((match = versePattern.exec(verse)) !== null) {
            verses.push({ number: match[1], text: match[2] });
        }
    } else {
        verses = [{ number: "", text: verse }]
    }

    const handlePress = () => {
        Haptics.impactAsync(Haptics.ImpactFeedbackStyle.neutralVeryLight);
        setIsExpanded(!isExpanded);
    };

    return (
        <View style={[styles.result, style]}>
            <TouchableHighlight onPress={handlePress} underlayColor="#9ED1B0" style={{ borderRadius: '12%' }}>
                <View style={styles.resultCard}>
                    <View style={locationRowStyle}>
                        <Text style={styles.resultLocation}>{location}</Text>
                        <Text style={styles.resultSimilarity}>
                            Similarity: {(similarity * 100).toFixed(1)}%
                        </Text>
                    </View>
                    {isExpanded && verses.map(({ number, text }, index) => (
                        <Text key={index} style={styles.resultText}>
                            {!singleVerse ? (
                                <>
                                    <Text style={styles.verseNumber}>{number}</Text>
                                    {text}
                                </>
                            ) : (
                                text
                            )}
                        </Text>
                    ))}
                </View>
            </TouchableHighlight>
        </View >
    );

};


const styles = StyleSheet.create({
    result: {
        padding: 4
    },
    resultCard: {
        backgroundColor: colorScheme.neutralVeryLight,
        borderRadius: '12%',
        padding: '4%',
        elevation: 5,
        borderColor: colorScheme.primaryLight,
        // borderWidth: 1,

    },
    resultLocation: {
        fontFamily: secondaryFont,
        fontSize: scaleFontSize(16),
        color: colorScheme.primaryLight,
    },
    resultText: {
        fontFamily: primaryFont,
        fontSize: scaleFontSize(14),
        marginBottom: '1.8%',
        color: colorScheme.neutralDark,
    },
    resultSimilarity: {
        fontFamily: primaryFont,
        fontSize: scaleFontSize(12),
        color: colorScheme.secondary,
    },
    verseNumber: {
        fontFamily: secondaryFont,
    },
});

export default SearchResult;
