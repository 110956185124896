import React from 'react';
import { View, ActivityIndicator, StyleSheet } from 'react-native';
import { MotiView } from 'moti';

import { colorScheme, scaleFontSize } from './styles';

const LoadingIndicator = ({ size = 'large', color = colorScheme.backgroundDark, style }) => {
    return (
        <View style={styles.container}>
            <MotiView
                from={{
                    width: scaleFontSize(15),
                    height: scaleFontSize(15),
                    borderRadius: scaleFontSize(15) / 2,
                    borderWidth: 0.1
                }}
                animate={{
                    width: scaleFontSize(15) + scaleFontSize(45),
                    height: scaleFontSize(15) + scaleFontSize(45),
                    borderRadius: (scaleFontSize(15) + scaleFontSize(45)) / 2,
                    borderWidth: (scaleFontSize(15) + scaleFontSize(25)) / 10,
                }}
                transition={{
                    type: 'timing',
                    duration: 1000,
                    repeat: Infinity,
                }}
                style={{
                    width: scaleFontSize(15),
                    height: scaleFontSize(15),
                    borderRadius: scaleFontSize(15) / 2,
                    borderWidth: scaleFontSize(15) / 10,
                    borderColor: colorScheme.primary,
                }}

            />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '50%',
    },
});

export default LoadingIndicator;
