import React, { useState, useRef, useEffect } from 'react';
import {
    View,
    Text,
    TextInput,
    TouchableOpacity,
    FlatList,
    StyleSheet,
    Keyboard,
    TouchableWithoutFeedback,
    KeyboardAvoidingView,
    Image,
    Platform,
    SafeAreaView,
    ScrollView,
    Dimensions, PixelRatio,
} from 'react-native';
import RadioGroup from './RadioGroup';
import SearchInput from './SearchInput';
import SearchResult from './SearchResult';
import LoadingIndicator from './LoadingIndicator';
import { colorScheme, secondaryFont, primaryFont, titleFont, scaleFontSize } from './styles';
import * as Haptics from 'expo-haptics';

import { LinearGradient } from 'expo-linear-gradient';

// import { useFonts, Cabin_400Regular, Cabin_700Bold } from '@expo-google-fonts/cabin';
// import { useFonts, RobotoCondensed_400Regular, RobotoCondensed_700Bold } from '@expo-google-fonts/roboto-condensed';
import { useFonts, Poppins_400Regular, Poppins_700Bold, Poppins_700Bold_Italic } from '@expo-google-fonts/poppins';
import { BACKEND_URL } from '../config';

const SearchPage = () => {
    const [cachedResults, setCachedResults] = useState({});
    const [searchBy, setSearchBy] = useState('verse');
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const [isFocused, setIsFocused] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const inputRef = useRef(null);
    let [fontsLoaded] = useFonts({
        // Cabin_400Regular,
        // Cabin_700Bold,
        // RobotoCondensed_400Regular,
        // RobotoCondensed_700Bold,
        Poppins_400Regular,
        Poppins_700Bold,
        Poppins_700Bold_Italic,
    });

    const handleSearch = async (searchByValue) => {
        if (query.length === 0 || isLoading) {
            return;
        }

        const cacheKey = `${searchByValue}-${query}`;

        if (cachedResults[cacheKey]) {
            setResults(cachedResults[cacheKey]);
            return;
        }

        setIsLoading(true);
        setError(null);
        const queryParams = new URLSearchParams();
        queryParams.append('search_by', searchByValue);
        queryParams.append('query', query);
        if (query.length === 0) {
            setIsLoading(false);
            return;
        }

        try {
            console.log(queryParams.toString());
            const response = await fetch(`${BACKEND_URL}/search?${queryParams.toString()}`);
            // const response = await fetch(`https://e74c-2605-ad80-10-940a-b5ae-c493-8e79-d5c2.ngrok-free.app/search?${queryParams.toString()}`);
            if (!response.ok) {
                console.log(response.status);
                throw new Error('Failed to fetch data from the backend');
            }
            const data = await response.json(); // Parse the JSON response
            setResults(data);

            // Cache the results
            setCachedResults((prevCachedResults) => {
                return {
                    ...prevCachedResults,
                    [cacheKey]: data,
                };
            });

            // Trigger haptic feedback after successful search
            Haptics.notificationAsync(Haptics.NotificationFeedbackType.Success);
        } catch (err) {
            console.log(err);
            setError('An error occurred while searching. Please try again.');

            // Trigger haptic feedback on error
            Haptics.notificationAsync(Haptics.NotificationFeedbackType.Error);
        } finally {
            setIsLoading(false);
        }
    };



    const showError = (message) => {
        Haptics.notificationAsync(Haptics.NotificationFeedbackType.Error);
    };

    const handleInputFocus = () => {
        Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium);
        setIsFocused(true);
        inputRef.current.focus();
    };

    const dismissKeyboard = () => {
        Keyboard.dismiss();
    };

    return (
        <SafeAreaView style={styles.container}>
            {fontsLoaded ? (
                <>
                    <View style={styles.logoContainer}>
                        <Image source={require('../assets/BrandName.png')} resizeMode="contain" style={styles.logoImage} />
                    </View>
                    <TouchableWithoutFeedback onPress={dismissKeyboard}>
                        <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : 'height'}>
                            <SearchInput
                                ref={inputRef}
                                value={query}
                                onChangeText={setQuery}
                                onFocus={handleInputFocus}
                                onSubmitEditing={() => handleSearch(searchBy)}
                                onBlur={() => {
                                    setIsFocused(false);
                                    setResults([]);
                                }}
                                isLoading={isLoading}
                                style={styles.searchInput}
                            />
                        </KeyboardAvoidingView>
                    </TouchableWithoutFeedback>
                    <View style={styles.radioWrapper}>
                        <RadioGroup
                            options={['verse', 'passage', 'chapter']}
                            selectedValue={searchBy}
                            onValueChange={(val) => {
                                if (val === searchBy) {
                                    return;
                                }
                                Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium);

                                setSearchBy(val);
                                handleSearch(val);
                            }}
                            isFocused={isFocused}
                            isLoading={isLoading}
                            style={styles.radioGroup}
                        />
                        <Text style={styles.label}>KJV</Text>
                    </View>

                    <View style={styles.resultsContainer}>
                        {isLoading ? (
                            <LoadingIndicator />
                        ) : error ? (
                            showError(error)
                        ) : results.length === 0 ? (
                            <Text style={styles.emptyText}>
                                No results found for your search. Please try again with a different query.
                            </Text>
                        ) : (
                            <FlatList
                                data={results}
                                keyExtractor={(item, index) => index.toString()}
                                renderItem={({ item }) => (
                                    <SearchResult
                                        singleVerse={searchBy === 'verse'}
                                        location={item.location}
                                        verse={item.verse}
                                        similarity={item.similarities.toFixed(4)}
                                        style={styles.searchResult}
                                    />
                                )}
                            />
                        )}
                    </View>

                </>
            ) : (
                <View style={styles.loadingIndicator}>
                    <LoadingIndicator />
                </View>
            )}
        </SafeAreaView>
    );
};



const styles = StyleSheet.create({
    container: {
        flex: 2,
        padding: '2%',
        paddingTop: 0,
        marginTop: '2%',
        marginHorizontal: '5%',
        overflow: 'hidden',
    },
    title: {
        flex: 1,
        justifyContent: 'flex-start',
        fontSize: scaleFontSize(18),
        fontWeight: 'bold',
        textAlign: 'center',
        marginTop: '5%',
        marginBottom: '5%',
        fontFamily: secondaryFont,
        color: colorScheme.primaryDark,
    },
    emptyText: {
        fontFamily: primaryFont,
        fontSize: scaleFontSize(15),
        textAlign: 'center',
        color: colorScheme.secondary,
        marginTop: '1%',
        marginBottom: '1%',
        marginHorizontal: '5%',
    },
    errorText: {
        fontFamily: primaryFont,
        fontSize: scaleFontSize(15),
        textAlign: 'center',
        color: '#ff0000',
        marginTop: '3%',
    },
    resultsContainer: {
        flex: 1,
        marginTop: '5%',
        marginBottom: '5%',
    },
    loadingIndicator: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    radioWrapper: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',

    },
    label: {
        fontSize: scaleFontSize(15),
        color: colorScheme.primaryDark,
        fontFamily: primaryFont,
    },
    radioGroup: {
        alignSelf: 'flex-start',
        marginTop: 0,
    },
    searchInput: {
        marginBottom: '4%',
        marginTop: '5%',
        backgroundColor: colorScheme.neutralVeryLight,
    },
    searchResult: {
        paddingHorizontal: 0,
        paddingVertical: '1.3%',
    },
    resultsContainer: {
        flex: 1,

        marginBottom: 0,
        height: '100%',
    },
    logoContainer: {
        marginTop: '3%',
        flex: 0.075,
        justifyContent: 'center',
        alignItems: 'center',

    },
    logoImage: {
        resizeMode: 'contain',
        width: '75%',

    },
    logoText: {
        fontFamily: titleFont,
        fontSize: scaleFontSize(26),
        color: colorScheme.primaryDark,
    },
});

export default SearchPage;
