import React from 'react';
import { TouchableOpacity, View, Text, StyleSheet } from 'react-native';
import { colorScheme, primaryFont, secondaryFont, scaleFontSize } from './styles';
import * as Haptics from 'expo-haptics';

const RadioGroup = ({ options, selectedValue, onValueChange, isFocused, isLoading, style }) => {
    return (
        <View style={[styles.radioContainer, isFocused ? styles.focusedRadioContainer : {}, style]}>
            {options.map((option) => (
                <TouchableOpacity
                    key={option}
                    onPress={() => {
                        if (!isLoading) {
                            onValueChange(option);
                            Haptics.neutralVeryLight;
                        }
                    }}
                    style={[
                        styles.radio,
                        selectedValue === option ? styles.selectedRadio : styles.unselectedRadio,
                    ]}
                    accessibilityLabel={`Search by ${option} `}
                    disabled={isLoading} // Add this line to disable TouchableOpacity when loading
                >
                    <Text
                        style={[
                            styles.radioText,
                            selectedValue === option ? styles.selectedRadioText : {},
                        ]}
                    >
                        {option}
                    </Text>
                </TouchableOpacity>
            ))}
        </View>
    );
};


RadioGroup.defaultProps = {
    options: ['verse', 'chapter', 'passage'],
    selectedValue: 'verse',
    onValueChange: () => { },
};

const styles = StyleSheet.create({
    // ... radioContainer, focusedRadioContainer, radio, selectedRadio, unselectedRadio, selectedRadioText, radioText
    radioContainer: {
        flexDirection: 'row',
        height: '80%',
        width: '64%',
        alignSelf: 'center',
        marginBottom: '4%',
        elevation: 5,
        borderColor: colorScheme.primaryLight,
    },
    radio: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: '1%',
    },
    selectedRadio: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colorScheme.primaryDark,
        borderRadius: '15%',
        paddingVertical: 6,
    },
    unselectedRadio: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        color: colorScheme.primaryDark,
    },
    selectedRadioText: {
        fontSize: scaleFontSize(15),
        color: colorScheme.backgroundLight,
        fontFamily: primaryFont,
    },
    radioText: {
        fontSize: scaleFontSize(15),
        color: colorScheme.primaryDark,
        fontFamily: primaryFont,
    },
});

export default RadioGroup;
